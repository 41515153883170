import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import editIcon from '../../../../assets/icons/pen-active.svg';

import useUser from '../../../../hooks/useUser';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import Avatar from '../../../UI/Avatar';
import classes from './styles.module.scss';

const ProjectManager = ({ projectManager, isEngagementOpen, onEditClick }) => {
  const { isAdmin, isProjectManager } = useUser();

  const isEditVisible = (isAdmin || isProjectManager) && isEngagementOpen;

  return (
    <div className={classes.ProjectManager}>
      <span className={classes.title}>Designated PM:</span>
      <div className={classes.content}>
        <Avatar
          imagePath={projectManager?.avatarPath}
          alt={projectManager?.name}
        />
        <span className={classes.pmText}>
          <span className={cn(classes.text, classes.name)}>
            {projectManager?.name}
          </span>
          <span className={classes.text}>
            {isEditVisible && (
              <RoundButtonWithIcon
                onClick={onEditClick}
                icon={editIcon}
                iconAlt="Edit PM"
                iconSize={8}
                classnames={[classes.icon]}
              />
            )}
            {!isEditVisible && (
              <Link
                target="_blank"
                to={{ pathname: 'https://form.jotform.com/243175185629161' }}
                className={classes.LinkButton}
              >
                Contact
              </Link>
            )}
          </span>
        </span>
      </div>
    </div>
  );
};

export default ProjectManager;
