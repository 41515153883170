import React from 'react';
import Cookies from 'js-cookie';

import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import UserService from '../../services/UserService';
import { USER_ROLES } from '../../constants/main';

const RedirectionHOC = (WrappedComponent) => {
  return (props) => {
    const history = useHistory();
    const {
      data: user,
      isLoading: isUserLoading,
      isSuccess,
    } = useQuery('currentUser', UserService.getMe, {
      staleTime: Infinity,
      retry: 0,
      enabled: !!Cookies.get('login-path'),
    });

    if (isUserLoading) {
      return null;
    }

    if (
      isSuccess &&
      user.roles.includes(USER_ROLES.CORE_PROS) &&
      ['/login', '/admin-login', '/getting-started'].includes(
        history.location.pathname
      )
    ) {
      history.push('/engagements');
    }

    return <WrappedComponent {...props} />;
  };
};

export default RedirectionHOC;
