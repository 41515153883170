// import axios from 'axios';
import { axiosPrivate, axiosPrivateFormData } from '../api/axios';

const ChatService = {
  async createOrUpdateConversation({
    userIds,
    engagementId,
    conversationDescription,
    groupAvatar,
    conversationId,
  }) {
    const form = new FormData();

    form.append('userIds', JSON.stringify(userIds));
    form.append('engagementId', engagementId);
    form.append('conversationDescription', conversationDescription);
    form.append('groupAvatar', groupAvatar);
    if (conversationId) {
      form.append('conversationId', conversationId);
    }

    const response = await axiosPrivateFormData.post(
      '/chat/create-or-update-conversation',
      form
    );

    return response.data;
  },
  async addParticipant(converstationId, userId) {
    const response = await axiosPrivate.patch(
      `/chat/add-participants/${converstationId}/${userId}`
    );
    return response.data;
  },
  async removeParticipant(converstationId, userId) {
    const response = await axiosPrivate.patch(
      `/chat/remove-participants/${converstationId}/${userId}`
    );
    return response.data;
  },
  async updateConversationStatus({ conversationId, action }) {
    const response = await axiosPrivate.patch(
      `/chat/update-conversation-status/${conversationId}/${action}`
    );
    return response.data;
  },
  async deleteConversation(converstationId) {
    const response = await axiosPrivate.delete(
      `/chat/delete-conversation/${converstationId}`
    );
    return response.data;
  },
  async getArchivedConversations() {
    const response = await axiosPrivate.get('/chat/archived-conversations', {});

    return response.data;
  },
};

export default ChatService;
