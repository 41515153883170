import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

import logoutIcon from '../../assets/icons/logout.svg';
import cancelIcon from '../../assets/icons/cross.svg';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import UserService from '../../services/UserService';
import useLogout from '../../hooks/useLogout';
import useUser from '../../hooks/useUser';
import useCheckDesktopScreen from '../../hooks/useCheckDesktopScreen';
import AvatarUpload from '../../components/UI/AvatarUpload';
import Input from '../../components/UI/Input';
import RoundButtonWithIcon from '../../components/UI/Buttons/RoundButtonWithIcon';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import ErrorMessage from '../../components/UI/ErrorMessage';
import PasswordInputs from '../../components/CreateOrEditUserForm/PasswordInputs';
import classes from './styles.module.scss';
import { UiContext } from '../../context/UiContext';
import validateFileSize from '../../helpers/validateFileSize';
import PhoneNumberInput from '../../components/UI/PhoneNumberInput';

const validationSchema = yup.object({
  fullName: yup.string().trim().required('Full Name is required.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  companyName: yup.string().trim(),
  roles: yup.array(),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.'),
  confirmPassword: yup.string().when('password', {
    is: (password) => password?.length > 0,
    then: (schema) =>
      schema
        .required('Confirm Password is required.')
        .oneOf([yup.ref('password')], 'Passwords must match.'),
  }),
  phoneNumber: yup
    .string()
    .test('invalid-phone-number', 'Invalid phone number.', (value) => {
      return !value || isValidPhoneNumber(value);
    }),
});

const AccountSettingsPage = () => {
  const { showErrorModal, showConfirmActionModal } = useContext(UiContext);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [avatarFile, setAvatarFile] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { currentUser, isAdmin } = useUser();
  const isDesktop = useCheckDesktopScreen();
  const handleLogout = useLogout();

  const queryClient = useQueryClient();

  const initialValues = useMemo(
    () => ({
      fullName: currentUser?.name,
      email: currentUser?.email,
      companyName: currentUser?.companyName,
      roles: currentUser?.roles,
      password: '',
      confirmPassword: '',
      phoneNumber: currentUser?.phone || '',
    }),
    [currentUser]
  );

  const { mutate: updateUserMutation } = useMutation(UserService.updateUser, {
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData('currentUser', (currentUserData) => {
          return {
            ...currentUserData,
            ...data,
          };
        });
      }

      setAvatarFile(null);
      setIsPasswordVisible(false);
    },
    onError: (error) => {
      console.log(error);
      showErrorModal({
        message: error.response.data.message || error.message,
      });
    },
  });

  const { mutate: deleteAvatarMutation } = useMutation(
    UserService.deleteUserAvatar,
    {
      onSuccess: () => {
        setAvatarPreview('');
        setAvatarFile(null);
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  useEffect(() => {
    if (currentUser?.avatarPath) {
      setAvatarPreview(currentUser.avatarPath);
    }
  }, [currentUser]);

  const handleSubmit = useCallback(
    ({ fullName, email, companyName, phoneNumber, password }) => {
      updateUserMutation({
        id: currentUser.id,
        ...(isAdmin && {
          name: fullName,
          email,
        }),
        companyName,
        avatar: avatarFile || null,
        ...(password && { password }),
        phone: phoneNumber || null,
      });
    },
    [currentUser, avatarFile, updateUserMutation]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);

      formik.setFieldValue('password', '');
      formik.setFieldValue('confirmPassword', '');
    },
  });

  const handleAvatarUploadChange = useCallback((event) => {
    const file = event.target.files[0];

    if (file) {
      if (!validateFileSize(file.size, 5)) {
        showErrorModal({
          message: 'Max file upload size is 5mb.',
        });
        return;
      }

      setAvatarFile(file);

      const reader = new FileReader();

      reader.onload = (e) => {
        setAvatarPreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const handleAvatarDeleteClick = useCallback(() => {
    showConfirmActionModal({
      title: `You are about to delete avatar, are you sure?`,
      handleSubmit: () => {
        deleteAvatarMutation(currentUser.id);
      },
    });
  });

  const handlePasswordVisibilityToggle = useCallback(() => {
    setIsPasswordVisible((prevState) => !prevState);
  }, []);

  const handleCancelChanges = useCallback(() => {
    setIsPasswordVisible(false);
    setAvatarFile(null);
    formik.setValues(initialValues);
    formik.setErrors({});

    if (currentUser?.avatarPath) {
      setAvatarPreview(currentUser.avatarPath);
    } else {
      setAvatarPreview('');
    }
  }, [currentUser, formik, initialValues]);

  const isSomeValueChanged = Object.keys(initialValues).some((key) => {
    if (key === 'confirmPassword') {
      return false;
    }

    return String(initialValues[key]) !== String(formik.values[key] || '');
  });

  const isFormEditing = isSomeValueChanged || avatarFile || isPasswordVisible;
  const isSaveButtonDisabled =
    !formik.isValid || (!isSomeValueChanged && !avatarFile);

  const bottomRightButton = useMemo(() => {
    if (isFormEditing) {
      return (
        <PrimaryButton onClick={formik.handleSubmit} iconName="checkmark">
          Save
        </PrimaryButton>
      );
    }
    return <></>;
  }, [formik.handleSubmit, isFormEditing]);

  return (
    <div className={classes.AccountSettingsPage}>
      <HeaderMenuContainer
        headerTitle="Settings"
        isUserBadgeVisible={!isDesktop}
        contentClassname={classes.content}
      >
        <div className={classes.top}>
          <h1 className={classes.title}>Settings</h1>
          <form className={classes.form}>
            <div className={classes.avatarContainer}>
              <AvatarUpload
                avatarPreview={avatarPreview}
                handleAvatarUploadChange={handleAvatarUploadChange}
                handleAvatarDeleteClick={handleAvatarDeleteClick}
              />
              <span onClick={handleLogout} className={classes.logout}>
                <img src={logoutIcon} alt="Logout" />
                Logout
              </span>
            </div>

            <div className={classes.formGroup}>
              <div className={classes.inputContainer}>
                <span className={classes.inputTitle}>Name</span>
                <Input
                  disabled={!isAdmin}
                  value={formik.values.fullName}
                  name="fullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classnames={[classes.input]}
                  hasError={formik.touched.fullName && formik.errors.fullName}
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <ErrorMessage message={formik.errors.fullName} />
                )}
              </div>

              <div className={classes.inputContainer}>
                <span className={classes.inputTitle}>Email</span>
                <Input
                  disabled={!isAdmin}
                  value={formik.values.email}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classnames={[classes.input]}
                  hasError={formik.touched.email && formik.errors.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <ErrorMessage message={formik.errors.email} />
                )}
              </div>

              <div className={classes.inputContainer}>
                <span className={classes.inputTitle}>Company</span>
                <Input
                  disabled={!isAdmin}
                  value={formik.values.companyName}
                  name="companyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classnames={[classes.input]}
                  hasError={
                    formik.touched.companyName && formik.errors.companyName
                  }
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <ErrorMessage message={formik.errors.companyName} />
                )}
              </div>
              <div className={classes.inputContainer}>
                <span className={classes.inputTitle}>Phone</span>
                <PhoneNumberInput
                  country={formik.values.country}
                  value={formik.values.phoneNumber}
                  name="phoneNumber"
                  onChange={(value) => {
                    formik.setFieldTouched('phoneNumber', true, true);
                    formik.setFieldValue('phoneNumber', value);
                  }}
                  onBlur={() =>
                    formik.setFieldTouched('phoneNumber', true, true)
                  }
                  classnames={[classes.input]}
                  placeholder="Enter phone number (optional)"
                  hasError={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  error={formik.errors.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <ErrorMessage message={formik.errors.phoneNumber} />
                )}
              </div>

              <PasswordInputs
                isPasswordVisible={isPasswordVisible}
                onPasswordVisibilityToggle={handlePasswordVisibilityToggle}
                passwordValue={formik.values.password}
                confirmPasswordValue={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isPasswordTouched={formik.touched.password}
                isConfirmPasswordTouched={formik.touched.confirmPassword}
                passwordError={formik.errors.password}
                confirmPasswordError={formik.errors.confirmPassword}
              />

              {isFormEditing && (
                <div className={classes.buttonsContainer}>
                  <PrimaryButton
                    onClick={handleCancelChanges}
                    variant="outline"
                  >
                    Cancel
                  </PrimaryButton>

                  <PrimaryButton
                    onClick={formik.handleSubmit}
                    disabled={isSaveButtonDisabled}
                  >
                    Save
                  </PrimaryButton>
                </div>
              )}
            </div>
          </form>
        </div>

        <div className={classes.bottom}>
          <span
            onClick={handlePasswordVisibilityToggle}
            className={classes.changePassword}
          >
            Change Password
          </span>
          <div className={classes.buttonsContainer}>
            {isFormEditing && (
              <RoundButtonWithIcon
                onClick={handleCancelChanges}
                icon={cancelIcon}
                iconAlt="Cancel"
                iconSize={26}
                classnames={[classes.roundButton]}
              />
            )}
            {bottomRightButton}
          </div>
        </div>
      </HeaderMenuContainer>
    </div>
  );
};

export default AccountSettingsPage;
