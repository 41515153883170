import React, { useState, useCallback, useMemo, useContext } from 'react';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { PAGINATION_COMMANDS, USER_ROLES } from '../../constants/main';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import UserService from '../../services/UserService';
import EngagementService from '../../services/EngagementService';
import useUser from '../../hooks/useUser';
import useSearchState from '../../hooks/useSearchState';
import ChangeUserModal from '../../modals/ChangeUserModal';
import BackButton from '../../components/UI/Buttons/BackButton';
import EngagementsItem from '../../components/Engagements/EngagementsItem';
import PrevNextPagination from '../../components/Engagements/PrevNextPagination';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

const EngagementPage = () => {
  const { showErrorModal } = useContext(UiContext);
  const [changeUserType, setChangeUserType] = useState('');

  const [
    serviceProviderSearchValue,
    debouncedServiceProviderSearchValue,
    handleServiceProviderSearchValueChange,
    clearServiceProviderSearchValue,
  ] = useSearchState();
  const [
    customerSearchValue,
    debouncedCustomerSearchValue,
    handleCustomerSearchValueChange,
    clearCustomerSearchValue,
  ] = useSearchState();
  const [
    projectManagersSearch,
    debouncedProjectManagersSearch,
    handleProjectManagersSearchChange,
    clearProjectManagersSearchValue,
  ] = useSearchState();

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { isAdmin, isProjectManager } = useUser();
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = queryParams.get('tab');

  const { data } = useQuery(
    ['engagements'],
    () =>
      EngagementService.getEngagements({
        page: 1,
        limit: 99999,
        type: selectedTab,
      }),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: engagement,
    refetch: refetchEngagement,
    isFetched,
  } = useQuery(
    ['engagements', id],
    () => EngagementService.getEngagementById(id),
    {
      enabled: !!id,
    }
  );

  const { data: serviceProviders } = useQuery(
    ['serviceProviders', debouncedServiceProviderSearchValue],
    () =>
      UserService.getUsers({
        roles: [USER_ROLES.SERVICE_PROVIDER, USER_ROLES.CORE_PROS],
        search: debouncedServiceProviderSearchValue,
      }),
    {
      keepPreviousData: true,
      enabled: isAdmin || isProjectManager,
    }
  );

  const { data: customers } = useQuery(
    ['customers', debouncedCustomerSearchValue],
    () =>
      UserService.getUsers({
        roles: [USER_ROLES.CUSTOMER],
        search: debouncedCustomerSearchValue,
      }),
    {
      keepPreviousData: true,
      enabled: isAdmin || isProjectManager,
    }
  );

  const { data: projectManagers } = useQuery(
    ['projectManagers', debouncedProjectManagersSearch],
    () =>
      UserService.getUsers({
        roles: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.ADMIN],
        search: debouncedProjectManagersSearch,
      }),
    {
      keepPreviousData: true,
      enabled: isAdmin || isProjectManager,
    }
  );

  const { mutate: updateEngagementMutation } = useMutation(
    EngagementService.updateEngagement,
    {
      onSuccess: () => {
        refetchEngagement();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: deleteEngagementMutation } = useMutation(
    EngagementService.deleteEngagement,
    {
      onSuccess: () => {
        history.push('/engagements');
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const { mutate: createEngagementUserMutation } = useMutation(
    EngagementService.createEngagementUser,
    {
      onSuccess: () => {
        refetchEngagement();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: deleteEngagementUserMutation } = useMutation(
    EngagementService.deleteEngagementUser,
    {
      onSuccess: () => {
        refetchEngagement();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: updateEngagementUserMutation } = useMutation(
    EngagementService.updateEngagementUser,
    {
      onSuccess: () => {
        refetchEngagement();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handlePagination = useCallback(
    (direction) => {
      const availableEngagementIds = data.engagements.map((em) => em.id);
      const currentEngagementIndex = availableEngagementIds.indexOf(id);
      const totalEngagements = availableEngagementIds.length;

      const change = direction === PAGINATION_COMMANDS.NEXT ? 1 : -1;
      const targetEngagementIndex =
        (currentEngagementIndex + change + totalEngagements) % totalEngagements;

      const targetEngagementId = availableEngagementIds[targetEngagementIndex];

      window.scrollTo(0, 0);
      history.push(`/engagements/${targetEngagementId}`);
    },
    [data, history, id]
  );

  const handleChangeCustomerOrPM = (type) => {
    setChangeUserType(type);
  };

  const changeUserModalProps = useMemo(
    () => ({
      [USER_ROLES.CUSTOMER]: {
        show: changeUserType === USER_ROLES.CUSTOMER,
        handleClose: () => setChangeUserType(''),
        label: 'Change Customer',
        users: customers,
        selectedUser: engagement?.customer,
        onSubmit: (userId) => {
          updateEngagementMutation({
            id,
            customerId: userId,
          });
        },
        searchValue: customerSearchValue,
        onSearch: handleCustomerSearchValueChange,
        clearSearchValue: clearCustomerSearchValue,
      },
      [USER_ROLES.PROJECT_MANAGER]: {
        show: changeUserType === USER_ROLES.PROJECT_MANAGER,
        handleClose: () => setChangeUserType(''),
        label: 'Change Project Manager',
        users: projectManagers,
        selectedUser: engagement?.projectManager,
        onSubmit: (userId) => {
          updateEngagementMutation({
            id,
            userId,
          });
        },
        searchValue: projectManagersSearch,
        onSearch: handleProjectManagersSearchChange,
        clearSearchValue: clearProjectManagersSearchValue,
      },
    }),
    [
      changeUserType,
      customers,
      engagement,
      handleCustomerSearchValueChange,
      handleProjectManagersSearchChange,
      id,
      projectManagers,
      updateEngagementMutation,
      customerSearchValue,
      projectManagersSearch,
      clearCustomerSearchValue,
      clearProjectManagersSearchValue,
    ]
  );

  return (
    <div className={classes.EngagementPage}>
      <HeaderMenuContainer isUserBadgeVisible>
        <BackButton label="Back to Engagements" to="/engagements" />
        {isFetched && (
          <EngagementsItem
            engagement={engagement}
            serviceProviders={serviceProviders}
            handleUpdate={updateEngagementMutation}
            serviceProviderSearchValue={serviceProviderSearchValue}
            handleServiceProviderSearchValueChange={
              handleServiceProviderSearchValueChange
            }
            clearServiceProviderSearchValue={clearServiceProviderSearchValue}
            createEngagementUserMutation={createEngagementUserMutation}
            deleteEngagementUserMutation={deleteEngagementUserMutation}
            updateEngagementUserMutation={updateEngagementUserMutation}
            deleteEngagementMutation={deleteEngagementMutation}
            isOpen
            handleChangeCustomerOrPM={handleChangeCustomerOrPM}
          />
        )}

        <PrevNextPagination onPagination={handlePagination} />
      </HeaderMenuContainer>

      <ChangeUserModal {...changeUserModalProps[changeUserType]} />
    </div>
  );
};

export default EngagementPage;
