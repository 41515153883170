import React from 'react';

import LoginContainer from '../../containers/LoginContainer';
import LoginForm from '../../components/LoginForm';
import classes from './styles.module.scss';
import RedirectionHOC from '../../containers/RedirectionHOC';

const LoginPage = () => {
  return (
    <div className={classes.LoginPage}>
      <LoginContainer>
        <LoginForm />
      </LoginContainer>
    </div>
  );
};

export default RedirectionHOC(LoginPage);
