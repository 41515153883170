import React from 'react';

import LoginContainer from '../../containers/LoginContainer';
import classes from './styles.module.scss';
import LoginForm from '../../components/LoginForm';
import RedirectionHOC from '../../containers/RedirectionHOC';

const AdminLoginPage = () => {
  return (
    <div className={classes.AdminLoginPage}>
      <LoginContainer variant="admin">
        <LoginForm adminLogin />
      </LoginContainer>
    </div>
  );
};

export default RedirectionHOC(AdminLoginPage);
