import React, { useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import cn from 'classnames';
import { useMutation } from 'react-query';
import { isValidPhoneNumber } from 'react-phone-number-input';

import eyeIcon from '../../assets/icons/eye.svg';

import { USER_ROLES } from '../../constants/main';
import { login } from '../../helpers/globalAuth';
import Input from '../UI/Input';
import ErrorMessage from '../UI/ErrorMessage';
import PrimaryButton from '../UI/Buttons/PrimaryButton';
import { UiContext } from '../../context/UiContext';
import PhoneNumberInput from '../UI/PhoneNumberInput';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';

const validationSchema = yup.object({
  emailOrPhone: yup.string().when('phoneLogin', {
    is: true,
    then: (schema) =>
      schema.test('invalid-phone-number', 'Invalid phone number.', (value) => {
        return !value || isValidPhoneNumber(value);
      }),
    otherwise: (schema) =>
      schema
        .trim()
        .email('Please enter a valid email address.')
        .required('Email is required.'),
  }),
  passwordOrCode: yup.string().when('phoneLogin', {
    is: true,
    then: (schema) =>
      schema
        .trim()
        .length(6, 'Code must 6 digit long.')
        .required('Code is required.'),
    otherwise: (schema) => schema.trim().required('Password is required.'),
  }),
});

const LoginForm = ({ adminLogin = false }) => {
  const { showErrorModal } = useContext(UiContext);
  const [shouldResendCode, setShouldResendCode] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isGlobalErrorVisible, setIsGlobalErrorVisible] = useState(false);

  const history = useHistory();

  const handleSubmit = async ({ emailOrPhone, passwordOrCode, rememberme }) => {
    try {
      await login({
        emailOrPhone,
        passwordOrCode,
        roles: adminLogin
          ? [USER_ROLES.ADMIN]
          : [
              USER_ROLES.CUSTOMER,
              USER_ROLES.PROJECT_MANAGER,
              USER_ROLES.SERVICE_PROVIDER,
              USER_ROLES.CORE_PROS,
            ],
        rememberme,
      });
      Cookies.set('login-path', adminLogin ? '/admin-login' : '/login', {
        expires: 10,
      });
      history.push('/getting-started');
    } catch (error) {
      setIsGlobalErrorVisible(true);
      showErrorModal({
        message: error.message || 'Invalid email or password.',
      });
    }
  };

  const handlePasswordVisibilityToggle = () =>
    setIsPasswordVisible((prevState) => !prevState);

  const formik = useFormik({
    initialValues: {
      phoneLogin: false,
      emailOrPhone: '',
      passwordOrCode: '',
      rememberme: false,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { mutate: getCode, isLoading } = useMutation(UserService.sendOTP, {
    onError: (error) => {
      setIsGlobalErrorVisible(true);
      showErrorModal({
        message: error.response.data.message || error.message,
      });
    },
    onSettled: () => {
      setShouldResendCode(true);
    },
  });

  const hasEmailOrPhoneError =
    formik.touched.emailOrPhone && !!formik.errors.emailOrPhone;
  const hasPasswordOrCodeError =
    formik.touched.passwordOrCode && !!formik.errors.passwordOrCode;

  const handleChange = (e, fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      formik.setFieldTouched(fieldName, true, true);
    }
    if (isGlobalErrorVisible) {
      setIsGlobalErrorVisible(false);
    }
    formik.handleChange(fieldName)(e);
  };

  const passwordType = isPasswordVisible ? 'text' : 'password';
  const isFormValid =
    formik.isValid &&
    !hasEmailOrPhoneError &&
    !hasPasswordOrCodeError &&
    !!formik.values.emailOrPhone &&
    !!formik.values.passwordOrCode;

  return (
    <form onSubmit={formik.handleSubmit} className={classes.LoginForm}>
      <h1 className={classes.title}>
        {adminLogin ? 'Login as admin' : 'Welcome back!'}
      </h1>
      <div className={classes.inputs}>
        <div className={classes.inputContainer}>
          {formik.values.phoneLogin ? (
            <div className={classes.phoneNumberInputContainer}>
              <PhoneNumberInput
                autoFocus
                country={formik.values.country}
                value={formik.values.emailOrPhone}
                name="phone"
                type="tel"
                onChange={(value) => {
                  formik.setFieldTouched('emailOrPhone', true, true);
                  formik.setFieldValue('emailOrPhone', value);
                  if (isGlobalErrorVisible) {
                    setIsGlobalErrorVisible(false);
                  }
                }}
                onBlur={() =>
                  formik.setFieldTouched('emailOrPhone', true, true)
                }
                classnames={[classes.input]}
                placeholder="Enter phone number"
                hasError={hasEmailOrPhoneError || isGlobalErrorVisible}
                error={formik.errors.emailOrPhone}
              />
              <PrimaryButton
                disabled={
                  isLoading ||
                  !formik.values.emailOrPhone ||
                  hasEmailOrPhoneError
                }
                type="button"
                classnames={[classes.getCodeButton]}
                onClick={() => getCode(formik.values.emailOrPhone)}
              >
                {shouldResendCode ? 'Resend' : 'Get'} code
              </PrimaryButton>
            </div>
          ) : (
            <Input
              autoFocus
              value={formik.values.emailOrPhone}
              onChange={(e) => handleChange(e, 'emailOrPhone')}
              onBlur={formik.handleBlur}
              hasError={hasEmailOrPhoneError || isGlobalErrorVisible}
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="email"
            />
          )}
          {hasEmailOrPhoneError && (
            <ErrorMessage message={formik.errors.emailOrPhone} />
          )}
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.passwordContainer}>
            <Input
              value={formik.values.passwordOrCode}
              onChange={(e) => handleChange(e, 'passwordOrCode')}
              onBlur={formik.handleBlur}
              hasError={hasPasswordOrCodeError || isGlobalErrorVisible}
              type={formik.values.phoneLogin ? 'text' : passwordType}
              name="passwordOrCode"
              placeholder={
                formik.values.phoneLogin
                  ? 'Enter the code you received'
                  : 'Password'
              }
              {...(formik.values.phoneLogin && {
                autoComplete: 'current-password',
              })}
              {...(formik.values.phoneLogin && {
                disabled: !formik.values.emailOrPhone || hasEmailOrPhoneError,
              })}
            />
            {!formik.values.phoneLogin && formik.values.passwordOrCode && (
              <button
                onClick={handlePasswordVisibilityToggle}
                type="button"
                className={cn(classes.passwordVisibilityToggle, {
                  [classes.slashed]: !isPasswordVisible,
                })}
              >
                <img src={eyeIcon} alt="Eye" className={classes.eyeIcon} />
              </button>
            )}
          </div>
          {hasPasswordOrCodeError && (
            <ErrorMessage message={formik.errors.passwordOrCode} />
          )}

          {isGlobalErrorVisible && (
            <ErrorMessage message="Invalid email or password." />
          )}
        </div>

        <label htmlFor="rememberme" className={classes.checkboxContainer}>
          <Input
            checked={formik.values.rememberme}
            onChange={(e) => handleChange(e, 'rememberme')}
            type="checkbox"
            id="rememberme"
          />
          <p>Remember me</p>
          {/* I honestly don't know why, but without this comment eslint shows error */}
        </label>

        <div className={classes.bottomActionLinks}>
          <span
            className={classes.loginWith}
            onClick={() => {
              const { phoneLogin } = formik.values;
              formik.resetForm();
              formik.setFieldValue('phoneLogin', !phoneLogin, true);
              setShouldResendCode(false);
            }}
          >
            Login with{' '}
            {formik.values.phoneLogin ? 'email and password' : 'phone'}
          </span>
          <Link className={classes.forgotPassword} to="/forgot-password">
            Forgot password
          </Link>
        </div>

        <PrimaryButton disabled={!isFormValid} type="submit">
          Login
        </PrimaryButton>

        {adminLogin && (
          <Link to="/login">
            <PrimaryButton type="button">I&#39;m Not An Admin</PrimaryButton>
          </Link>
        )}
      </div>
    </form>
  );
};

export default LoginForm;
