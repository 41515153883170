// import axios from 'axios';
import { axiosPrivate, axiosPrivateFormData } from '../api/axios';

const UserService = {
  async getMe() {
    const response = await axiosPrivate.get('/users/me');

    return response.data;
  },
  async getUsers({ page, limit, search, roles, withNoChargeMethod, archived }) {
    const response = await axiosPrivate.get('/users', {
      params: {
        page,
        limit,
        search,
        roles: JSON.stringify(roles),
        withNoChargeMethod,
        archived,
      },
    });

    return response.data;
  },
  async getUserById(id) {
    const response = await axiosPrivate.get(`/users/${id}`);

    return response.data;
  },
  async createUser({
    name,
    email,
    country,
    companyName,
    rate,
    roles,
    avatar,
    details,
    note,
    phone,
  }) {
    const form = new FormData();

    form.append('file', avatar);
    form.append('name', name);
    form.append('email', email);
    form.append('country', country);
    form.append('companyName', companyName);
    form.append('rate', rate);
    form.append('roles', roles);
    form.append('details', details);
    form.append('note', note);
    form.append('phone', phone);

    const response = await axiosPrivateFormData.post('/users', form);

    return response.data;
  },
  async updateUser(data) {
    const { id, ...fields } = data;
    const form = new FormData();

    Object.keys(fields).forEach((field) => {
      const value = fields[field];
      form.append(field, value);
    });

    const response = await axiosPrivateFormData.patch(`/users/${id}`, form);

    return response.data;
  },
  async deleteUser(id) {
    const response = await axiosPrivate.delete(`/users/${id}`);

    return response.data;
  },
  async loginAs(id) {
    const response = await axiosPrivate.post(`/login-as/${id}`);
    return response.data;
  },
  async sendOTP(phoneNumber) {
    const response = await axiosPrivate.post('/send-otp', {
      phone_number: phoneNumber,
    });
    return response.data;
  },
  async deleteUserAvatar(id) {
    const response = await axiosPrivate.delete(`/users/avatar/${id}`);

    return response.data;
  },
};

export default UserService;
